import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import "./story.css";

export default function Story({data}) {
    const raw = data.contentfulStory.story.raw;
    const html = {
        __html: documentToHtmlString(JSON.parse(raw))
    };
    return (
        <Layout title={data.contentfulStory.title}>
            <h1>{data.contentfulStory.title}</h1>
            <article dangerouslySetInnerHTML={html} />
        </Layout>
    )
};

export const query = graphql`
    query StoryByTitle($title: String!) {
        contentfulStory(title: { eq: $title }) {
            title
            story {
                raw
            }
        }
    }
`;